:root {
  --theme: #6a69e0;
  --bg: #000;
  --bg2: #151517;
  --bg3: #202022;
  --color: #d4d4d4;
  --color2: #797979;
  --color3: #fff;
  --color4: #2a2a2a;
}

* {
  --lightningcss-light: ;
  --lightningcss-dark: initial;
  color-scheme: dark;
}

body {
  background: var(--bg);
  color: var(--color);
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, SF Pro, sans-serif;
  overflow-x: hidden;
}

.text-faded {
  color: var(--color2);
}

.dialog {
  z-index: 8;
  justify-content: center;
  align-items: center;
  display: -webkit-flex;
  display: flex;
  position: absolute;
  inset: 0;
}

.dialog-content {
  background: var(--bg2);
  color: var(--color);
  z-index: 1;
  border: none;
  border-radius: .6rem;
  max-width: min(20rem, 70vw);
  padding: 1rem;
}

.dialog:before {
  content: "";
  background: #000000bf;
  display: block;
  position: absolute;
  inset: 0;
}

h1 {
  color: var(--color3);
  margin-bottom: .5rem;
  font-size: xx-large;
  font-weight: 600;
}

nav {
  padding: .5rem;
}

main {
  margin: .5rem 4rem;
}

footer {
  border-top: 1px solid var(--color4);
  color: var(--color2);
  z-index: 3;
  view-transition-name: footer;
  width: 100%;
  margin-bottom: 0;
  position: fixed;
  bottom: 0;
}

hr {
  border: none;
  border-bottom: 1px solid var(--color4);
}

input[type="text"] {
  display: block-inline;
  background: var(--bg2);
  color: var(--color);
  border: 1px solid #0000;
  border-radius: .5393rem;
  outline: none;
  width: 100%;
  padding: .25rem 2.25rem;
  font-size: 1rem;
  line-height: 1.5;
  transition: border .25s;
}

input[type="text"]::-webkit-input-placeholder {
  color: var(--color2);
}

input[type="text"]::placeholder {
  color: var(--color2);
}

input[type="inline"]::-webkit-input-placeholder {
  color: var(--color2);
}

input[type="inline"]::placeholder {
  color: var(--color2);
}

input[type="text"]:focus, input[type="inline"]:focus {
  border: 1px solid var(--color2);
}

input[type="inline"] {
  display: block-inline;
  background: var(--bg3);
  color: var(--color);
  border: 1px solid #0000;
  border-radius: .5393rem;
  outline: none;
  padding: .25rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  transition: border .25s;
}

.search-form {
  align-items: center;
  width: 100%;
  display: -webkit-flex;
  display: flex;
}

.search-form svg {
  color: var(--color2);
  margin-left: .75rem;
  display: block;
  position: absolute;
  -webkit-transform: scale(.8);
  transform: scale(.8);
}

button, input[type="submit"] {
  cursor: pointer;
  color: #cdd6f4;
  background: #585b70;
  border: 1px solid #6c7086;
  border-radius: .25rem;
  padding: .1875rem .375rem;
  font-size: .75rem;
  font-weight: 400;
  line-height: 1.5;
  display: inline-block;
}

.app-icon-container {
  display: -webkit-flex;
  display: flex;
}

.appimg {
  -webkit-clip-path: url("#squircle");
  clip-path: src(#squircle);
  margin: .1rem;
}

.app-icon-details {
  -webkit-flex-direction: column;
  flex-direction: column;
  margin-left: 1rem;
  display: -webkit-flex;
  display: flex;
}

.app-icon-details h2 {
  margin-top: 0;
  margin-bottom: .1rem;
}

.app-icon-details .row {
  margin-top: .75rem;
}

.app-install-btn {
  text-align: center;
  background: var(--theme);
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border: none;
  border-radius: 10rem;
  width: 4.5rem;
  padding: .375rem .75rem;
  font-size: .85rem;
  font-weight: 700;
  transition-duration: .1s;
}

.app-install-btn.disabled {
  cursor: not-allowed;
  color: #aaa;
  background: #53527a;
}

.app-customize-btn {
  color: var(--theme);
  cursor: pointer;
  margin-left: .5rem;
  padding: .25rem;
}

.vlist {
  justify-content: center;
  align-items: left;
  -webkit-flex-direction: column;
  flex-direction: column;
  display: -webkit-flex;
  display: flex;
}

.row {
  align-items: center;
  display: -webkit-flex;
  display: flex;
}

.back, .add-source {
  cursor: pointer;
  color: var(--theme);
  align-items: center;
  font-weight: 500;
  display: -webkit-flex;
  display: flex;
}

.add-source {
  float: left;
}

.tabs {
  background: var(--bg2);
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding-top: .25rem;
  padding-bottom: .25rem;
  display: -webkit-flex;
  display: flex;
}

.tabs > div {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  -webkit-flex-direction: column;
  flex-direction: column;
  align-items: center;
  font-size: small;
  display: -webkit-flex;
  display: flex;
}

.tabs > div > * {
  margin: .1rem;
}

.tab-selected {
  color: var(--theme);
}

.list-item {
  align-items: center;
  margin: .65rem;
  display: -webkit-flex;
  display: flex;
}

.list-item-desc {
  margin-left: 1rem;
}

.list-item.placeholder span, .list-item.placeholder small {
  background: var(--color4);
  color: var(--color4);
}

.sources-btn {
  float: right;
  cursor: pointer;
  color: var(--theme);
  font-weight: 500;
}

#sources .sources-btn, #sources .add-source {
  margin: .5rem;
}

.settings-panel {
  background: var(--bg2);
  border-radius: .5625rem;
  margin-bottom: .5rem;
  padding: .4rem 1rem;
}

.settings-panel > hr {
  margin-top: .6rem;
  margin-bottom: .5rem;
  margin-right: -1rem;
}

#app-item-advanced > hr {
  margin-top: .6rem;
  margin-bottom: .5rem;
  margin-right: -1.25rem;
}

.settings-row {
  justify-content: space-between;
  align-items: center;
  display: -webkit-flex;
  display: flex;
}

.settings-button {
  cursor: pointer;
  color: var(--theme);
  background: initial;
  border-radius: initial;
  padding: initial;
  font-size: initial;
  font-weight: initial;
  line-height: initial;
  border: none;
  min-height: 2rem;
}

.settings-panel:has( > .settings-button:active), .settings-panel:has( > .settings-button:focus-visible) {
  background: var(--bg3);
}

.settings-button > label {
  cursor: pointer;
}

#app-item-advanced-url {
  text-overflow: ellipsis;
  color: inherit;
  text-align: right;
  white-space: nowrap;
  width: 80%;
  overflow: hidden;
}

.switch {
  -webkit-tap-highlight-color: transparent;
  float: right;
  width: 3.25rem;
  height: 2rem;
  display: inline-block;
  position: relative;
}

.switch-input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch-span {
  cursor: pointer;
  background: var(--color4);
  border-radius: 1rem;
  transition: all .4s;
  position: absolute;
  inset: 0;
}

.switch-span:before {
  content: "";
  background: #fff;
  border-radius: 50%;
  width: 1.7rem;
  height: 1.7rem;
  transition: all .4s;
  position: absolute;
  bottom: .175rem;
  left: .2rem;
}

.switch-special .switch-span:before {
  background: var(--theme);
}

.switch-input:checked + .switch-span {
  background: #30d158;
}

.switch-input:focus + .switch-span {
  box-shadow: 0 0 1px #333;
}

.switch-input:checked + .switch-span:before {
  background: #e6e6e6;
  -webkit-transform: translateX(1.2rem);
  transform: translateX(1.2rem);
}

.slow-rotate {
  -webkit-animation: 1s linear infinite slow-rotate;
  animation: 1s linear infinite slow-rotate;
}

@-webkit-keyframes slow-rotate {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes slow-rotate {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.badge {
  background: var(--theme);
  color: var(--color);
  border-radius: 10rem;
  padding: .1rem .5rem;
  font-size: small;
}

.about-window {
  -webkit-flex-direction: column;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 1rem 0;
  display: -webkit-flex;
  display: flex;
}

.sheet-container {
  justify-content: center;
  display: -webkit-flex;
  display: flex;
}

.sheet {
  background: var(--bg2);
  z-index: 5;
  border-top-left-radius: .6rem;
  border-top-right-radius: .6rem;
  -webkit-flex-direction: column;
  flex-direction: column;
  height: calc(95% - .5rem);
  margin: 0 1rem;
  padding: .5rem 1rem 0;
  -webkit-animation: .35s ease-in-out forwards sheet-up;
  animation: .35s ease-in-out forwards sheet-up;
  display: -webkit-flex;
  display: flex;
  position: fixed;
  bottom: 0;
}

.sheet-backdrop {
  z-index: 4;
  background: #000000bf;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.sheet-hide {
  -webkit-animation: .35s ease-in-out forwards sheet-down;
  animation: .35s ease-in-out forwards sheet-down;
}

.sheet > h1 {
  margin-top: .5rem;
}

#srcs-list {
  -webkit-flex: auto;
  flex: auto;
  margin-bottom: .3rem;
  padding-bottom: 1rem;
  overflow-y: auto;
}

.bottom-progress {
  background: var(--theme);
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  height: .25rem;
  margin-left: -1rem;
  transition: width .35s;
  position: fixed;
  bottom: 0;
}

.bottom-progress-done {
  -webkit-animation: .35s ease-in-out forwards progress-hide;
  animation: .35s ease-in-out forwards progress-hide;
}

@-webkit-keyframes progress-hide {
  to {
    bottom: -1rem;
  }
}

@keyframes progress-hide {
  to {
    bottom: -1rem;
  }
}

@-webkit-keyframes sheet-up {
  from {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }

  to {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
}

@keyframes sheet-up {
  from {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }

  to {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
}

@-webkit-keyframes sheet-down {
  from {
    -webkit-transform: translateY(% );
    transform: translateY(% );
  }

  to {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
}

@keyframes sheet-down {
  from {
    -webkit-transform: translateY(% );
    transform: translateY(% );
  }

  to {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
}

@media (width <= 600px) {
  main {
    margin: .5rem 1.25rem;
  }
}

@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
}

@-webkit-keyframes fade-out {
  to {
    opacity: 0;
  }
}

@keyframes fade-out {
  to {
    opacity: 0;
  }
}

@-webkit-keyframes slide-from-right {
  from {
    -webkit-transform: translateX(30px);
    transform: translateX(30px);
  }
}

@keyframes slide-from-right {
  from {
    -webkit-transform: translateX(30px);
    transform: translateX(30px);
  }
}

@-webkit-keyframes slide-to-left {
  to {
    -webkit-transform: translateX(-30px);
    transform: translateX(-30px);
  }
}

@keyframes slide-to-left {
  to {
    -webkit-transform: translateX(-30px);
    transform: translateX(-30px);
  }
}

::view-transition-old(root) {
  -webkit-animation: 90ms cubic-bezier(.4, 0, 1, 1) both fade-out, .3s cubic-bezier(.4, 0, .2, 1) both slide-to-left;
  animation: 90ms cubic-bezier(.4, 0, 1, 1) both fade-out, .3s cubic-bezier(.4, 0, .2, 1) both slide-to-left;
}

::view-transition-new(root) {
  -webkit-animation: .21s cubic-bezier(0, 0, .2, 1) 90ms both fade-in, .3s cubic-bezier(.4, 0, .2, 1) both slide-from-right;
  animation: .21s cubic-bezier(0, 0, .2, 1) 90ms both fade-in, .3s cubic-bezier(.4, 0, .2, 1) both slide-from-right;
}
/*# sourceMappingURL=index.ee9af604.css.map */
