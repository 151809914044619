:root {
  --theme: #6A69E0;
  --bg: #000;
  --bg2: #151517;
  --bg3: #202022;

  --color: #D4D4D4;
  --color2: #797979;
  --color3: #FFF;
  --color4: #2A2A2A;
}

/*
.material-symbols-rounded {
  font-variation-settings:
  'FILL' 1,
  'wght' 400,
  'GRAD' 0,
  'opsz' 24
}
*/

* {
  color-scheme: dark;
}

body {
  background: var(--bg);
  color: var(--color);
  font-family: -apple-system, BlinkMacSystemFont, SF Pro, sans-serif;

  overflow-x: hidden;

  margin: 0;
  padding: 0;
}

.text-faded {
  color: var(--color2);
}

.dialog {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 8;

  display: flex;
  justify-content: center;
  align-items: center;
}
.dialog-content {
  border-radius: .6rem;
  padding: 1rem;
  background: var(--bg2);
  color: var(--color);
  border: none;
  z-index: 1;

  max-width: min(20rem, 70vw);
}
.dialog::before {
  display: block;
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.75);
}

h1 {
  font-size: xx-large;
  font-weight: 600;
  color: var(--color3);
  margin-bottom: .5rem;
}

nav {
  padding: .5rem;
}

main {
  margin: .5rem 4rem;
}

footer {
  border-top: 1px solid var(--color4);
  color: var(--color2);
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 3;
  margin-bottom: 0;
  view-transition-name: footer;
}

hr {
  border: none;
  border-bottom: 1px solid var(--color4);
}

input[type=text] {
  display: block-inline;
  width: 100%;
  padding: .25rem 2.25rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .5393rem;
  border: 1px solid transparent;
  outline: none;
  transition: border 250ms ease;
  background: var(--bg2);
  color: var(--color);
}
input[type=text]::placeholder, input[type=inline]::placeholder {
  color: var(--color2);
}
input[type=text]:focus, input[type=inline]:focus {
  border: 1px solid var(--color2);
}

input[type=inline] {
  display: block-inline;
  padding: .25rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .5393rem;
  border: 1px solid transparent;
  outline: none;
  transition: border 250ms ease;
  background: var(--bg3);
  color: var(--color);
}

.search-form {
  width: 100%;
  display: flex;
  align-items: center;
}
.search-form svg {
  display: block;
  position: absolute;
  transform: scale(80%);
  color: var(--color2);
  margin-left: .75rem;
}

input[type=submit], button {
  display: inline-block;
  cursor: pointer;
  font-weight: 400;
  line-height: 1.5;
  padding: .1875rem .375rem;
  background: #585b70;
  color: #cdd6f4;
  font-size: .75rem;
  border: 1px solid #6c7086;
  border-radius: .25rem;
}

.app-icon-container {
  display: flex;
}

.appimg {
  /*border-radius: 1.0786rem;*/
  -webkit-clip-path: url(#squircle);
  clip-path: src(#squircle);
  margin: .1rem;
}

.app-icon-details {
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
}
.app-icon-details h2 {
  margin-bottom: .1rem;
  margin-top: 0rem;
}
.app-icon-details .row {
  margin-top: .75rem;
}

.app-install-btn {
  font-size: .85rem;
  width: 4.5rem;
  padding: .375rem .75rem;
  text-align: center;
  border-radius: 10rem;
  border: none;
  background: var(--theme);
  font-weight: 700;

  cursor: pointer;
  user-select: none;
  transition-duration: 100ms;
}
.app-install-btn.disabled {
  cursor: not-allowed;
  background: #53527A;
  color: #aaa;
}

.app-customize-btn {
  padding: .25rem;
  color: var(--theme);
  margin-left: .5rem;
  cursor: pointer;
}

.vlist {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
}

.row {
  display: flex;
  align-items: center;
}

.back, .add-source {
  cursor: pointer;
  color: var(--theme);
  font-weight: 500;

  display: flex;
  align-items: center;
}
.add-source {
  float: left;
}

.tabs {
  padding-top: .25rem;
  padding-bottom: .25rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: var(--bg2);
  width: 100%;
}
.tabs > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  user-select: none;
  font-size: small;
}
.tabs > div > * {
  margin: .1rem;
}

.tab-selected {
  color: var(--theme);
}

.list-item {
  margin: .65rem;
  display: flex;
  align-items: center;
}
.list-item-desc {
  margin-left: 1rem;
}
.list-item.placeholder span, .list-item.placeholder small {
  background: var(--color4);
  color: var(--color4);
}

.sources-btn {
  float: right;
  cursor: pointer;
  color: var(--theme);
  font-weight: 500;
}
#sources .sources-btn, #sources .add-source {
  margin: .5rem;
}

.settings-panel {
  border-radius: .5625rem;
  padding: .4rem 1rem;
  margin-bottom: .5rem;
  background: var(--bg2);
}
.settings-panel > hr {
  margin-right: -1rem;
  margin-top: .6rem;
  margin-bottom: .5rem;
}
#app-item-advanced > hr {
  margin-right: -1.25rem;
  margin-top: .6rem;
  margin-bottom: .5rem;
}

.settings-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.settings-button {
  cursor: pointer;
  min-height: 2rem;
  color: var(--theme);

  background: initial;
  border: none;
  border-radius: initial;
  padding: initial;
  font-size: initial;
  font-weight: initial;
  line-height: initial;
}
.settings-panel:has(> .settings-button:active), .settings-panel:has(> .settings-button:focus-visible) {
  background: var(--bg3);
}

.settings-button > label {
  cursor: pointer;
}

#app-item-advanced-url {
  text-overflow: ellipsis;
  color: inherit;
  text-align: right;
  width: 80%;
  overflow: hidden;
  white-space: nowrap
}

.switch {
  position: relative;
  display: inline-block;
  width: 3.25rem;
  height: 2rem;
  -webkit-tap-highlight-color: transparent;
  float: right;
}
.switch-input {
  opacity: 0;
  width: 0;
  height: 0;
}
.switch-span {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--color4);
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 1rem;
}
.switch-span:before {
  position: absolute;
  content: "";
  height: 1.7rem;
  width: 1.7rem;
  left: .2rem;
  bottom: .175rem;
  background: #fff;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}
.switch-special .switch-span:before {
  background: var(--theme);
}
.switch-input:checked + .switch-span {
  background: #30D158;
}
.switch-input:focus + .switch-span {
  box-shadow: 0 0 1px #333;
}
.switch-input:checked + .switch-span:before {
  background: #e6e6e6;
  -webkit-transform: translateX(1.2rem);
  -ms-transform: translateX(1.2rem);
  transform: translateX(1.2rem);
}

.slow-rotate {
  animation: 1s linear slow-rotate infinite;
}

@keyframes slow-rotate {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.badge {
  background: var(--theme);
  color: var(--color);
  font-size: small;
  padding: .1rem .5rem;
  border-radius: 10rem;
}

.about-window {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
}

.sheet-container {
  display: flex;
  justify-content: center;
}

.sheet {
  position: fixed;
  background: var(--bg2);
  height: calc(95% - .5rem);
  bottom: 0;
  z-index: 5;
  padding: 0 1rem;
  padding-top: .5rem;
  margin: 0 1rem;
  border-top-left-radius: .6rem;
  border-top-right-radius: .6rem;
  animation: 350ms ease-in-out sheet-up forwards;

  display: flex;
  flex-direction: column;
}
.sheet-backdrop {
  z-index: 4;
  position: fixed;
  background: rgba(0, 0, 0, 0.75);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.sheet-hide {
  animation: 350ms ease-in-out sheet-down forwards;
}

.sheet > h1 {
  margin-top: .5rem;
}

#srcs-list {
  flex: 1 1 auto;
  margin-bottom: .3rem;
  padding-bottom: 1rem;
  overflow-y: auto;
}

.bottom-progress {
  margin-left: -1rem;
  position: fixed;
  bottom: 0;
  height: .25rem;
  background: var(--theme);
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  transition: width 350ms;
}
.bottom-progress-done {
  animation: 350ms ease-in-out progress-hide forwards;
}

@keyframes progress-hide {
  to { bottom: -1rem }
}

@keyframes sheet-up {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0%);
  }
}
@keyframes sheet-down {
  from {
    transform: translateY(%);
  }
  to {
    transform: translateY(100%);
  }
}


@media (max-width: 600px) {
  main {
    margin: .5rem 1.25rem;
  }
}

@keyframes fade-in { from { opacity: 0; } }
@keyframes fade-out { to { opacity: 0; } }
@keyframes slide-from-right { from { transform: translateX(30px); } }
@keyframes slide-to-left { to { transform: translateX(-30px); } }
::view-transition-old(root) {
  animation: 90ms cubic-bezier(0.4, 0, 1, 1) both fade-out,
    300ms cubic-bezier(0.4, 0, 0.2, 1) both slide-to-left;
}
::view-transition-new(root) {
  animation: 210ms cubic-bezier(0, 0, 0.2, 1) 90ms both fade-in,
    300ms cubic-bezier(0.4, 0, 0.2, 1) both slide-from-right;
}

/*
flask.svg
gear.svg
magnifyingglass.svg
paintbrush.fill.svg
paintbrush.pointed.fill.svg
shippingbox.fill.svg
swatchpalette.svg
trash.svg
*/
